var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.dynamicTags, function (tag) {
        return _c(
          "el-tag",
          {
            key: tag,
            attrs: {
              closable: "",
              "disable-transitions": false,
              type: "primary",
              size: "medium",
            },
            on: {
              close: function ($event) {
                return _vm.handleClose(tag)
              },
            },
          },
          [_vm._v("\n    " + _vm._s(tag) + "\n  ")]
        )
      }),
      _vm.inputVisible
        ? _c("el-input", {
            ref: "saveTagInput",
            staticClass: "input-new-tag",
            attrs: { size: "medium" },
            on: {
              blur: _vm.handleInputConfirm,
              input: (val) => this.$emit("input", _vm.dynamicTags),
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleInputConfirm.apply(null, arguments)
              },
            },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          })
        : _c(
            "el-button",
            {
              staticClass: "button-new-tag",
              attrs: { size: "medium" },
              on: { click: _vm.showInput },
            },
            [_vm._v("+ Thêm Tag")]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }