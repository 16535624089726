var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "money el-input",
      class: {
        "is-disabled": _vm.disabled,
        "el-input-group el-input-group--append el-input-group--prepend":
          !!_vm.$slots.append,
      },
    },
    [
      !!_vm.$slots.prepend
        ? _c(
            "div",
            { staticClass: "el-input-group__prepend" },
            [_vm._t("prepend")],
            2
          )
        : _vm._e(),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.val,
            expression: "val",
          },
        ],
        ref: "ipt",
        staticClass: "el-input__inner",
        attrs: {
          autocomplete: "off",
          type: "text",
          rows: "2",
          disabled: _vm.disabled,
          validateevent: "true",
          placeholder: _vm.placeholder,
        },
        domProps: { value: _vm.val },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.val = $event.target.value
          },
        },
      }),
      !!_vm.$slots.append
        ? _c(
            "div",
            { staticClass: "el-input-group__append" },
            [_vm._t("append")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }