<template lang="html">
  <div class="card">
    <div class="card-header" v-if="group.title">
      <h6 class="title">{{group.title}}</h6>
    </div>
    <div class="card-body form-card">
      <div class="form-group row" v-for="attribute of group.attributes" :key="attribute.prop">
        <div class="col-sm-12" :class="{'col-lg-2 col-md-2' : !hasTop}">
          <label :class="attribute.validate?'required':''">{{attribute.label}} {{attribute.type == 'image' && attribute.size?'(' + attribute.size + ')':''}}</label>
        </div>
        <div class="col-sm-12" :class="{'col-lg-10 col-md-10' : !hasTop}">

          <!-- text -->
          <div v-if="attribute.type === 'text'">
            <el-input
              type="text"
              :placeholder="attribute.label"
              class="full-width"
              v-model="value[attribute.prop]"
              :ref="'input-' + attribute.prop"
              @input="val => handleInput(attribute.prop, val)"
              :class="errors.has(attribute.prop)?'border-danger':''"
              v-validate="attribute.validate?attribute.validate:''"
              :data-vv-name="attribute.prop"
              :data-vv-as="attribute.label"
            >
              <template slot="prepend" v-if="attribute.prop==='handle'">{{generateUrl}}/</template>
            </el-input>
            <span class="text-danger" v-if="errors.has(attribute.prop)">{{ errors.first(attribute.prop) }}</span>
          </div>

          <div v-if="attribute.type === 'password'">
            <el-input
              type="password"
              :placeholder="attribute.label"
              class="full-width"
              v-model="value[attribute.prop]"
              :ref="'input-' + attribute.prop"
              @input="val => handleInput(attribute.prop, val)"
              :class="errors.has(attribute.prop)?'border-danger':''"
              v-validate="attribute.validate?attribute.validate:''"
              :data-vv-name="attribute.prop"
              :data-vv-as="attribute.label"
            >
              <template slot="prepend" v-if="attribute.prop==='handle'">{{generateUrl}}/</template>
            </el-input>
            <span class="text-danger" v-if="errors.has(attribute.prop)">{{ errors.first(attribute.prop) }}</span>
          </div>

          <!-- number -->
          <div v-if="attribute.type === 'number'">
            <el-input
              type="number"
              :placeholder="attribute.label"
              class="full-width"
              v-model="value[attribute.prop]"
              @input="val => handleInput(attribute.prop, val)"
              :class="errors.has(attribute.prop)?'border-danger':''"
              v-validate="attribute.validate?attribute.validate:''"
              :data-vv-name="attribute.prop"
              :data-vv-as="attribute.label"
            >
            </el-input>
            <span class="text-danger" v-if="errors.has(attribute.prop)">{{ errors.first(attribute.prop) }}</span>
          </div>

          <!-- money -->
          <my-money
            v-if="attribute.type === 'money'"
            :placeholder="attribute.label"
            fixed=0
            v-model="value[attribute.prop]"
            @input="val => handleInput(attribute.prop, val)"
          >
            <template slot="append">đ</template>
          </my-money>

          <!-- date -->
          <el-date-picker
            v-if="attribute.type === 'date'"
            class="full-width"
            type="date"
            :placeholder="attribute.label"
            v-model="value[attribute.prop]"
            @input="val => handleInput(attribute.prop, val)"
          >
          </el-date-picker>

          <!--datetime-->
          <el-date-picker
            v-if="attribute.type === 'datetime'"
            class="full-width"
            v-model="value[attribute.prop]"
            type="datetimerange"
            range-separator="To"
            start-placeholder="Bắt đầu"
            end-placeholder="Kết thúc"
            @input="val => handleInput(attribute.prop, val)"
          >
          </el-date-picker>

          <!-- checkbox-->
          <el-switch
            v-if="attribute.type === 'checkbox'"
            v-model="value[attribute.prop]"
            @input="val => handleInput(attribute.prop, val)"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>

          <!-- radio -->
          <div v-if="attribute.type === 'radio'">
            <el-radio
              v-model="value[attribute.prop]"
              @input="val => handleInput(attribute.prop, val)"
              v-for="(option, index) in attribute.options"
              :key="index"
              :label="option.value"
              v-validate="attribute.validate?attribute.validate:''"
              :data-vv-name="attribute.prop"
              :data-vv-as="attribute.label"
            >
              {{option.title}}
            </el-radio>
            <br>
            <span class="text-danger" v-if="errors.has(attribute.prop)">{{ errors.first(attribute.prop) }}</span>
          </div>

          <!-- select -->
          <div v-if="attribute.type === 'select'">
            <my-select
              class="full-width"
              v-bind:attribute.sync="attribute"
              :placeholder="attribute.label"
              v-model="value[attribute.prop]"
              @input="val => handleInput(attribute.prop, val)"
              :class="errors.has(attribute.prop)?'border-danger':''"
              v-validate="attribute.validate?attribute.validate:''"
              :data-vv-name="attribute.prop"
              :data-vv-as="attribute.label"
            >
            </my-select>
            <span class="text-danger" v-if="errors.has(attribute.prop)">{{ errors.first(attribute.prop) }}</span>
          </div>

          <!-- textarea -->
          <div v-if="attribute.type === 'textarea'">
            <el-input
              type="textarea"
              :rows="3"
              :placeholder="attribute.label"
              v-model="value[attribute.prop]"
              @input="val => handleInput(attribute.prop, val)"
              :class="errors.has(attribute.prop)?'border-danger':''"
              v-validate="attribute.validate?attribute.validate:''"
              :data-vv-name="attribute.prop"
              :data-vv-as="attribute.label"
            >
            </el-input>
            <span class="text-danger" v-if="errors.has(attribute.prop)">{{ errors.first(attribute.prop) }}</span>
          </div>

          <!-- editor -->
          <div v-if="attribute.type === 'editor'">
            <my-editor
              @input="val => handleInput(attribute.prop, val)"
              v-model="value[attribute.prop]"
              apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23"
              :class="errors.has(attribute.prop)?'border-danger':''"
              v-validate="attribute.validate?attribute.validate:''"
              :data-vv-name="attribute.prop"
              :data-vv-as="attribute.label"
            ></my-editor>
            <span class="text-danger" v-if="errors.has(attribute.prop)">{{ errors.first(attribute.prop) }}</span>
          </div>

          <!-- image -->
          <my-image
            v-if="attribute.type === 'image'"
            v-model="value[attribute.prop]"
            @input="val => handleInput(attribute.prop, val)"
          >
          </my-image>

          <!-- file * -->
          <div class="col-lg-5 col-md-5 col-sm-12 p-l-0" v-if="attribute.type === 'file'">
            <el-upload
              class="upload"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList">
              <el-button class="btn btn-primary" size="small" type="primary">Chọn file</el-button>
              <!-- <div slot="tip" class="el-upload__tip">Ghi chú????</div> -->
            </el-upload>
          </div>

          <!-- source -->
          <select-ajax
            v-if="$_.includes(ajaxTypes, attribute.type)"
            v-bind:attribute.sync="attribute"
            v-model="value[attribute.prop]"
            @input="val => handleInput(attribute.prop, val)"
            :attribute="attribute"
          >
          </select-ajax>

          <!-- map -->
          <my-map v-if="attribute.type=='maps'" :center="center"></my-map>

          <!-- tag -->
          <my-tag
            v-if="attribute.type=='tag'"
            v-model="value[attribute.prop]"
            @input="val => handleInput(attribute.prop, val)"
          ></my-tag>
        </div>
      </div>
    </div>
    <toast position="se"></toast>
  </div>
</template>

<script>
  import {Select, Option, Button, Tag, Input, Tabs, TabPane, Upload, DatePicker, Switch, Radio, MessageBox} from 'element-ui'
  import Editor from '@tinymce/tinymce-vue'
  import MyEditor from 'src/components/UIComponents/Editor'
  import SelectAjax from 'src/components/UIComponents/SelectAjax'
  import MySelect from 'src/components/UIComponents/Select'
  import MyMap from 'src/components/UIComponents/Map'
  import MyTag from 'src/components/UIComponents/Tag'
  import MyMoney from 'src/components/UIComponents/Money'
  import MyImage from 'src/components/UIComponents/Image'
  import { Toast } from 'vuex-toast'
  import { mapActions } from 'vuex'
  import { ADD_TOAST_MESSAGE } from 'vuex-toast'

  export default {
    inject: ['$validator'],
    props: ['group', 'hasTop', 'value'],
    data() {
      return {
        ajaxTypes: [
          'product', 'collection', 'article', 'blog', 'page', 'galleries', 'roles',
          'products', 'collections', 'articles', 'blogs', 'pages', 'gallery', 'role'
        ],
        center: {
          lat: 40.748817,
          lng: -73.985428
        },
        fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],
        imageUrl: '',
        imageName: ''
      }
    },
    components: {
      ElSelect: Select,
      ElOption: Option,
      ElButton: Button,
      ElTag: Tag,
      ElInput: Input,
      ElTabs: Tabs,
      ElTabPane: TabPane,
      ElUpload: Upload,
      ElDatePicker: DatePicker,
      ElSwitch: Switch,
      ElRadio: Radio,
      editor: Editor,
      MyEditor,
      SelectAjax,
      MySelect,
      MyMap,
      MyTag,
      MyMoney,
      Toast,
      MyImage
    },
    computed: {
      generateUrl() {
        return location.protocol + '//' + location.host;
      }
    },
    created() {
    },
    methods: {
      ...mapActions({
        addToast: ADD_TOAST_MESSAGE
      }),
      toastr(text, type) {
        this.addToast({
          text,
          type: type,
          dismissAfter: 3000
        })
      },
      handleRemove(file, fileList) {
      },
      handlePreview(file) {
      },
      handleExceed(files, fileList) {
        this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
      },
      beforeRemove(file, fileList) {
        return MessageBox.confirm(`Bạn có muốn xóa ${ file.name }？`);
      },
      remoteMethod(query) {
        this.dataSource = [];
        if (query !== '') {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.dataSource = this.list.filter(item => {
              return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
            });
          }, 200);
        } else {
          this.dataSource = [];
        }
      },

      handleInput(field, val) {
        let oldValue = this.value[field];
        this.$emit('input', this.value);
        this.$emit('field-update', field, val, oldValue);
      },

      onFileChange(item, e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.createImage(item, files[0]);
      },

      createImage(item, file) {
        var image = new Image();
        var reader = new FileReader();
        reader.onload = (e) => {
          item.image = e.target.result;
        };
        reader.readAsDataURL(file);
      },

      removeImage: function (item) {
        item.image = false; 
      }
    }
  }
</script>

<style lang="css">
</style>
