var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: "select",
      staticClass: "full-width",
      attrs: {
        filterable: "",
        remote: "",
        multiple: _vm.isMultiple,
        placeholder: "Tìm kiếm " + _vm.type + "...",
        remoteMethod: _vm.remoteMethod,
        "no-data-text": "Không tìm thấy kết quả",
        "loading-text": "Đang tải...",
        "value-key": "id",
      },
      on: { input: (val) => this.$emit("input", val) },
      model: {
        value: _vm.selectValues,
        callback: function ($$v) {
          _vm.selectValues = $$v
        },
        expression: "selectValues",
      },
    },
    _vm._l(_vm.dataSource, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item.label, value: item },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }