var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticClass: "row p-0" },
        [
          _c("gmap-autocomplete", {
            staticClass: "form-control el-input__inner",
            attrs: { placeholder: "Nhập địa điểm..." },
            on: { place_changed: _vm.setPlace },
          }),
        ],
        1
      ),
      _c(
        "Gmap-Map",
        {
          staticStyle: { width: "100%", height: "300px" },
          attrs: { zoom: 15, center: _vm.center },
        },
        [
          _c("Gmap-Marker", {
            attrs: {
              position: _vm.marker.position,
              clickable: true,
              draggable: true,
            },
            on: {
              click: function ($event) {
                _vm.center = _vm.m.position
              },
            },
          }),
          this.place
            ? _c("Gmap-Marker", {
                attrs: {
                  position: {
                    lat: this.place.geometry.location.lat(),
                    lng: this.place.geometry.location.lng(),
                  },
                  clickable: true,
                  draggable: true,
                },
                on: {
                  click: function ($event) {
                    _vm.center = _vm.m.position
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }