var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card" },
    [
      _vm.group.title
        ? _c("div", { staticClass: "card-header" }, [
            _c("h6", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.group.title)),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "card-body form-card" },
        _vm._l(_vm.group.attributes, function (attribute) {
          return _c(
            "div",
            { key: attribute.prop, staticClass: "form-group row" },
            [
              _c(
                "div",
                {
                  staticClass: "col-sm-12",
                  class: { "col-lg-2 col-md-2": !_vm.hasTop },
                },
                [
                  _c("label", { class: attribute.validate ? "required" : "" }, [
                    _vm._v(
                      _vm._s(attribute.label) +
                        " " +
                        _vm._s(
                          attribute.type == "image" && attribute.size
                            ? "(" + attribute.size + ")"
                            : ""
                        )
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "col-sm-12",
                  class: { "col-lg-10 col-md-10": !_vm.hasTop },
                },
                [
                  attribute.type === "text"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-input",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: attribute.validate
                                    ? attribute.validate
                                    : "",
                                  expression:
                                    "attribute.validate?attribute.validate:''",
                                },
                              ],
                              ref: "input-" + attribute.prop,
                              refInFor: true,
                              staticClass: "full-width",
                              class: _vm.errors.has(attribute.prop)
                                ? "border-danger"
                                : "",
                              attrs: {
                                type: "text",
                                placeholder: attribute.label,
                                "data-vv-name": attribute.prop,
                                "data-vv-as": attribute.label,
                              },
                              on: {
                                input: (val) =>
                                  _vm.handleInput(attribute.prop, val),
                              },
                              model: {
                                value: _vm.value[attribute.prop],
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, attribute.prop, $$v)
                                },
                                expression: "value[attribute.prop]",
                              },
                            },
                            [
                              attribute.prop === "handle"
                                ? _c("template", { slot: "prepend" }, [
                                    _vm._v(_vm._s(_vm.generateUrl) + "/"),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.errors.has(attribute.prop)
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first(attribute.prop))
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  attribute.type === "password"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-input",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: attribute.validate
                                    ? attribute.validate
                                    : "",
                                  expression:
                                    "attribute.validate?attribute.validate:''",
                                },
                              ],
                              ref: "input-" + attribute.prop,
                              refInFor: true,
                              staticClass: "full-width",
                              class: _vm.errors.has(attribute.prop)
                                ? "border-danger"
                                : "",
                              attrs: {
                                type: "password",
                                placeholder: attribute.label,
                                "data-vv-name": attribute.prop,
                                "data-vv-as": attribute.label,
                              },
                              on: {
                                input: (val) =>
                                  _vm.handleInput(attribute.prop, val),
                              },
                              model: {
                                value: _vm.value[attribute.prop],
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, attribute.prop, $$v)
                                },
                                expression: "value[attribute.prop]",
                              },
                            },
                            [
                              attribute.prop === "handle"
                                ? _c("template", { slot: "prepend" }, [
                                    _vm._v(_vm._s(_vm.generateUrl) + "/"),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.errors.has(attribute.prop)
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first(attribute.prop))
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  attribute.type === "number"
                    ? _c(
                        "div",
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: attribute.validate
                                  ? attribute.validate
                                  : "",
                                expression:
                                  "attribute.validate?attribute.validate:''",
                              },
                            ],
                            staticClass: "full-width",
                            class: _vm.errors.has(attribute.prop)
                              ? "border-danger"
                              : "",
                            attrs: {
                              type: "number",
                              placeholder: attribute.label,
                              "data-vv-name": attribute.prop,
                              "data-vv-as": attribute.label,
                            },
                            on: {
                              input: (val) =>
                                _vm.handleInput(attribute.prop, val),
                            },
                            model: {
                              value: _vm.value[attribute.prop],
                              callback: function ($$v) {
                                _vm.$set(_vm.value, attribute.prop, $$v)
                              },
                              expression: "value[attribute.prop]",
                            },
                          }),
                          _vm.errors.has(attribute.prop)
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first(attribute.prop))
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  attribute.type === "money"
                    ? _c(
                        "my-money",
                        {
                          attrs: { placeholder: attribute.label, fixed: "0" },
                          on: {
                            input: (val) =>
                              _vm.handleInput(attribute.prop, val),
                          },
                          model: {
                            value: _vm.value[attribute.prop],
                            callback: function ($$v) {
                              _vm.$set(_vm.value, attribute.prop, $$v)
                            },
                            expression: "value[attribute.prop]",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("đ")])],
                        2
                      )
                    : _vm._e(),
                  attribute.type === "date"
                    ? _c("el-date-picker", {
                        staticClass: "full-width",
                        attrs: { type: "date", placeholder: attribute.label },
                        on: {
                          input: (val) => _vm.handleInput(attribute.prop, val),
                        },
                        model: {
                          value: _vm.value[attribute.prop],
                          callback: function ($$v) {
                            _vm.$set(_vm.value, attribute.prop, $$v)
                          },
                          expression: "value[attribute.prop]",
                        },
                      })
                    : _vm._e(),
                  attribute.type === "datetime"
                    ? _c("el-date-picker", {
                        staticClass: "full-width",
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "To",
                          "start-placeholder": "Bắt đầu",
                          "end-placeholder": "Kết thúc",
                        },
                        on: {
                          input: (val) => _vm.handleInput(attribute.prop, val),
                        },
                        model: {
                          value: _vm.value[attribute.prop],
                          callback: function ($$v) {
                            _vm.$set(_vm.value, attribute.prop, $$v)
                          },
                          expression: "value[attribute.prop]",
                        },
                      })
                    : _vm._e(),
                  attribute.type === "checkbox"
                    ? _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        on: {
                          input: (val) => _vm.handleInput(attribute.prop, val),
                        },
                        model: {
                          value: _vm.value[attribute.prop],
                          callback: function ($$v) {
                            _vm.$set(_vm.value, attribute.prop, $$v)
                          },
                          expression: "value[attribute.prop]",
                        },
                      })
                    : _vm._e(),
                  attribute.type === "radio"
                    ? _c(
                        "div",
                        [
                          _vm._l(attribute.options, function (option, index) {
                            return _c(
                              "el-radio",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: attribute.validate
                                      ? attribute.validate
                                      : "",
                                    expression:
                                      "attribute.validate?attribute.validate:''",
                                  },
                                ],
                                key: index,
                                attrs: {
                                  label: option.value,
                                  "data-vv-name": attribute.prop,
                                  "data-vv-as": attribute.label,
                                },
                                on: {
                                  input: (val) =>
                                    _vm.handleInput(attribute.prop, val),
                                },
                                model: {
                                  value: _vm.value[attribute.prop],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value, attribute.prop, $$v)
                                  },
                                  expression: "value[attribute.prop]",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(option.title) +
                                    "\n          "
                                ),
                              ]
                            )
                          }),
                          _c("br"),
                          _vm.errors.has(attribute.prop)
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first(attribute.prop))
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  attribute.type === "select"
                    ? _c(
                        "div",
                        [
                          _c("my-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: attribute.validate
                                  ? attribute.validate
                                  : "",
                                expression:
                                  "attribute.validate?attribute.validate:''",
                              },
                            ],
                            staticClass: "full-width",
                            class: _vm.errors.has(attribute.prop)
                              ? "border-danger"
                              : "",
                            attrs: {
                              attribute: attribute,
                              placeholder: attribute.label,
                              "data-vv-name": attribute.prop,
                              "data-vv-as": attribute.label,
                            },
                            on: {
                              "update:attribute": function ($event) {
                                attribute = $event
                              },
                              input: (val) =>
                                _vm.handleInput(attribute.prop, val),
                            },
                            model: {
                              value: _vm.value[attribute.prop],
                              callback: function ($$v) {
                                _vm.$set(_vm.value, attribute.prop, $$v)
                              },
                              expression: "value[attribute.prop]",
                            },
                          }),
                          _vm.errors.has(attribute.prop)
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first(attribute.prop))
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  attribute.type === "textarea"
                    ? _c(
                        "div",
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: attribute.validate
                                  ? attribute.validate
                                  : "",
                                expression:
                                  "attribute.validate?attribute.validate:''",
                              },
                            ],
                            class: _vm.errors.has(attribute.prop)
                              ? "border-danger"
                              : "",
                            attrs: {
                              type: "textarea",
                              rows: 3,
                              placeholder: attribute.label,
                              "data-vv-name": attribute.prop,
                              "data-vv-as": attribute.label,
                            },
                            on: {
                              input: (val) =>
                                _vm.handleInput(attribute.prop, val),
                            },
                            model: {
                              value: _vm.value[attribute.prop],
                              callback: function ($$v) {
                                _vm.$set(_vm.value, attribute.prop, $$v)
                              },
                              expression: "value[attribute.prop]",
                            },
                          }),
                          _vm.errors.has(attribute.prop)
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first(attribute.prop))
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  attribute.type === "editor"
                    ? _c(
                        "div",
                        [
                          _c("my-editor", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: attribute.validate
                                  ? attribute.validate
                                  : "",
                                expression:
                                  "attribute.validate?attribute.validate:''",
                              },
                            ],
                            class: _vm.errors.has(attribute.prop)
                              ? "border-danger"
                              : "",
                            attrs: {
                              apiKey:
                                "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                              "data-vv-name": attribute.prop,
                              "data-vv-as": attribute.label,
                            },
                            on: {
                              input: (val) =>
                                _vm.handleInput(attribute.prop, val),
                            },
                            model: {
                              value: _vm.value[attribute.prop],
                              callback: function ($$v) {
                                _vm.$set(_vm.value, attribute.prop, $$v)
                              },
                              expression: "value[attribute.prop]",
                            },
                          }),
                          _vm.errors.has(attribute.prop)
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first(attribute.prop))
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  attribute.type === "image"
                    ? _c("my-image", {
                        on: {
                          input: (val) => _vm.handleInput(attribute.prop, val),
                        },
                        model: {
                          value: _vm.value[attribute.prop],
                          callback: function ($$v) {
                            _vm.$set(_vm.value, attribute.prop, $$v)
                          },
                          expression: "value[attribute.prop]",
                        },
                      })
                    : _vm._e(),
                  attribute.type === "file"
                    ? _c(
                        "div",
                        { staticClass: "col-lg-5 col-md-5 col-sm-12 p-l-0" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload",
                              attrs: {
                                action:
                                  "https://jsonplaceholder.typicode.com/posts/",
                                "on-preview": _vm.handlePreview,
                                "on-remove": _vm.handleRemove,
                                "before-remove": _vm.beforeRemove,
                                multiple: "",
                                limit: 3,
                                "on-exceed": _vm.handleExceed,
                                "file-list": _vm.fileList,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { size: "small", type: "primary" },
                                },
                                [_vm._v("Chọn file")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$_.includes(_vm.ajaxTypes, attribute.type)
                    ? _c("select-ajax", {
                        attrs: { attribute: attribute, attribute: attribute },
                        on: {
                          "update:attribute": function ($event) {
                            attribute = $event
                          },
                          input: (val) => _vm.handleInput(attribute.prop, val),
                        },
                        model: {
                          value: _vm.value[attribute.prop],
                          callback: function ($$v) {
                            _vm.$set(_vm.value, attribute.prop, $$v)
                          },
                          expression: "value[attribute.prop]",
                        },
                      })
                    : _vm._e(),
                  attribute.type == "maps"
                    ? _c("my-map", { attrs: { center: _vm.center } })
                    : _vm._e(),
                  attribute.type == "tag"
                    ? _c("my-tag", {
                        on: {
                          input: (val) => _vm.handleInput(attribute.prop, val),
                        },
                        model: {
                          value: _vm.value[attribute.prop],
                          callback: function ($$v) {
                            _vm.$set(_vm.value, attribute.prop, $$v)
                          },
                          expression: "value[attribute.prop]",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
      _c("toast", { attrs: { position: "se" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }