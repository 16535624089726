<template lang="html">
  <div class="">
    <div class="row p-0">
      <gmap-autocomplete
        @place_changed="setPlace"
        class="form-control el-input__inner"
        placeholder="Nhập địa điểm..."
      >
      </gmap-autocomplete>
      <!-- <div class="col-lg-10 col-md-10 col-sm-10 p-0">

      </div> -->
      <!-- <div class="col-lg-2 col-md-2 col-sm-2 p-r-0">
        <el-button class="full-width" type="primary" @click="usePlace">Chọn</el-button>
      </div> -->
    </div>

    <Gmap-Map style="width: 100%; height: 300px;" :zoom="15" :center="center">
      <Gmap-Marker
        :position="marker.position"
        :clickable="true"
        :draggable="true"
        @click="center=m.position"
      ></Gmap-Marker>
      <Gmap-Marker
        v-if="this.place"
        :position="{
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        }"
        :clickable="true"
        :draggable="true"
        @click="center=m.position"
      ></Gmap-Marker>
    </Gmap-Map>
  </div>
</template>

<script>
  import {Select, Option, Button, Tag, Input} from 'element-ui'
  import * as VueGoogleMaps from 'vue2-google-maps'

  export default {
    props: ['center'],
    components: {
      ElSelect: Select,
      ElOption:Option,
      ElButton:Button,
      ElTag:Tag,
      ElInput:Input
    },
    data() {
      return {
        marker: [],
        place: null
      }
    },
    mounted(){

    },
    methods: {
      setDescription(description) {
        this.description = description;
      },
      setPlace(place) {
        this.place = place;
        this.usePlace(place);
      },
      usePlace(place) {
        if (this.place) {
          this.marker = {
            position: {
              lat: this.place.geometry.location.lat(),
              lng: this.place.geometry.location.lng(),
            }
          }
          this.center = {
            lat: this.place.geometry.location.lat(),
            lng: this.place.geometry.location.lng(),
          }
          this.place = null;
        }
      }
    }
  }
</script>

<style lang="css" scoped>
  .p-0{
    padding: 0 !important;
  }
  .p-r-0{
    padding-right: 0 !important;
  }
</style>
