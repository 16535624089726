<template lang="html">
  <el-select
    ref="select"
    class="full-width"
    v-model="selectValues"
    filterable
    remote
    :multiple="isMultiple"
    :placeholder="'Tìm kiếm ' + type + '...'"
    :remoteMethod="remoteMethod"
    no-data-text="Không tìm thấy kết quả"
    loading-text="Đang tải..."
    value-key="id"
    @input="val => this.$emit('input', val)"
  >
    <el-option
      v-for="item in dataSource"
      :key="item.id"
      :label="item.label"
      :value="item"
      >
    </el-option>
  </el-select>
</template>

<script>
  import {Select, Option} from 'element-ui'
  import api from 'src/store/api';

  export default {
    props: ['attribute', 'value'],
    components: {
      ElSelect: Select,
      ElOption: Option
    },
    data() {
      return {
        selectValues: [],
        dataSource:[],
        loading: false,
        model: '',
        isMultiple: true
      }
    },
    computed: {
      type() {
        let nameHash = {
          'products': 'sản phẩm',
          'product': 'sản phẩm',

          'collections': 'nhóm sản phẩm',
          'collection': 'nhóm sản phẩm',

          'articles': 'bài viết',
          'article': 'bài viết',

          'blogs': 'nhóm bài viết',
          'blog': 'nhóm bài viết',

          'pages': 'trang nội dung',
          'page': 'trang nội dung',

          'galleries': 'gallery',
          'gallery': 'gallery',

          'customers': 'khách hàng',
          'customer': 'khách hàng',

          'roles': 'phân quyền',
          'role': 'phân quyền',
        }
        return nameHash[this.attribute.type];
      }
    },
    mounted() {
      this.model = this.$util.findModel(this.attribute.type);
      this.isMultiple = this.attribute.multiple || this.$util.isMultiple(this.attribute.type);
    },
    watch: {
      value(newVal, oldVal){
        if (this.isMultiple) {
          this.selectValues = newVal;
          this.dataSource = newVal;
        } else{
          this.selectValues = newVal.label;
          this.dataSource = Array(newVal);
        }
      }
    },
    methods: {
      remoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          api.get(`/admin/api/object/${this.model}?term=${query}`).then(resp => {
            this.dataSource = resp.data.map(obj => ({
              id: obj.id,
              label: obj.value,
              handle: obj.handle
            }));
            this.loading = false;
          }).catch(e => {
            console.log('err', e);
          })
        } else {
          this.dataSource = [];
        }
      }
    }
  }
</script>

<style lang="css">
</style>
