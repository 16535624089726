import { render, staticRenderFns } from "./SelectAjax.vue?vue&type=template&id=1b05cd3e&lang=html&"
import script from "./SelectAjax.vue?vue&type=script&lang=js&"
export * from "./SelectAjax.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/truyen/workspace/dlvn/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b05cd3e')) {
      api.createRecord('1b05cd3e', component.options)
    } else {
      api.reload('1b05cd3e', component.options)
    }
    module.hot.accept("./SelectAjax.vue?vue&type=template&id=1b05cd3e&lang=html&", function () {
      api.rerender('1b05cd3e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/UIComponents/SelectAjax.vue"
export default component.exports